import React from 'react';

const EdenorMide: React.FC = () => (
  <svg
    width="152"
    height="28"
    viewBox="0 0 152 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="72.5311"
      height="27.751"
      rx="5.84232"
      fill="white"
      fillOpacity="0.75"
    />
    <rect
      x="79.4685"
      width="72.5311"
      height="27.751"
      rx="5.84232"
      fill="white"
      fillOpacity="0.75"
    />
    <rect
      x="93.3022"
      y="9.94556"
      width="8.28607"
      height="10.4957"
      fill="white"
    />
    <path
      d="M111.153 18.631L108.47 13.8549V20.0818C108.47 20.6082 108.02 21.0576 107.494 21.0576C106.968 21.0576 106.518 20.6082 106.518 20.0818V10.2599C106.518 9.73355 106.968 9.28418 107.494 9.28418C107.918 9.28418 108.2 9.52812 108.367 9.83626L111.988 16.3328L115.608 9.83626C115.775 9.52812 116.058 9.28418 116.481 9.28418C117.008 9.28418 117.457 9.73355 117.457 10.2599V20.0947C117.457 20.6211 117.008 21.0705 116.481 21.0705C115.955 21.0705 115.506 20.6211 115.506 20.0947V13.8677L112.822 18.6439C112.655 18.952 112.321 19.1189 111.988 19.1189C111.654 19.1189 111.32 18.9392 111.153 18.631Z"
      fill="#59BA47"
    />
    <path
      d="M121.039 20.0691C121.039 20.6212 120.59 21.0577 120.05 21.0577C119.498 21.0577 119.062 20.6084 119.062 20.0691V10.2729C119.062 9.72083 119.511 9.2843 120.05 9.2843C120.602 9.2843 121.039 9.73367 121.039 10.2729V20.0691Z"
      fill="#59BA47"
    />
    <path
      d="M125.931 9.41199C129.333 9.41199 131.49 10.9527 131.49 15.1767C131.49 19.4008 129.333 20.9286 125.931 20.9286H123.62C123.068 20.9286 122.631 20.4793 122.631 19.94V10.4134C122.631 9.86135 123.081 9.42483 123.62 9.42483H125.931V9.41199ZM124.609 19.144H125.931C128.126 19.144 129.436 18.1554 129.436 15.1767C129.436 12.1981 128.114 11.1966 125.931 11.1966H124.609V19.144Z"
      fill="#59BA47"
    />
    <path
      d="M134.597 14.2536H137.833C138.308 14.2536 138.706 14.6516 138.706 15.1267C138.706 15.6017 138.308 15.9997 137.833 15.9997H134.597V19.1453H139.13C139.63 19.1453 140.015 19.5433 140.015 20.0312C140.015 20.5191 139.617 20.9171 139.13 20.9171H133.609C133.057 20.9171 132.62 20.4677 132.62 19.9285V10.4148C132.62 9.86268 133.069 9.42615 133.609 9.42615H138.95C139.45 9.42615 139.836 9.82416 139.836 10.312C139.836 10.8128 139.438 11.1979 138.95 11.1979H134.61V14.2536H134.597Z"
      fill="#59BA47"
    />
    <path
      d="M98.2119 20.6342C98.2761 20.6342 98.3403 20.6342 98.4045 20.6342C99.3802 20.6342 100.343 20.7241 101.216 20.1848C102.333 19.4915 102.115 18.015 102.115 16.898C102.115 15.5885 102.115 14.2917 102.115 12.9821C102.115 12.0962 102.064 11.1461 101.845 10.2859C101.589 9.24596 100.292 9.10473 99.4059 9.05337C97.8524 8.9635 96.286 9.05337 94.7325 9.05337C91.8052 9.10473 92.46 12.9308 92.46 14.8566C92.46 16.7055 91.741 20.814 94.7068 20.6471C95.8623 20.57 97.0435 20.6342 98.2119 20.6342ZM100.497 19.3246C99.714 19.8125 98.2504 19.5301 97.403 19.5301C96.4144 19.5301 95.3359 19.6841 94.3601 19.5172C93.2303 19.3246 93.6026 17.2704 93.6026 16.4358C93.6026 14.9337 93.6026 13.4187 93.6026 11.9165C93.6026 11.0948 93.6925 10.196 94.7196 10.196C96.0164 10.196 97.3131 10.196 98.6099 10.196C98.9951 10.196 99.3802 10.1575 99.7654 10.196C100.189 10.2346 100.703 10.2731 100.818 10.7353C100.947 11.236 100.947 11.7624 100.985 12.276C101.049 13.3416 101.011 14.4073 101.011 15.4857C101.011 16.4102 101.434 18.7597 100.497 19.3246Z"
      fill="#1F568C"
    />
    <path
      d="M95.3234 12.8162C96.556 12.8162 97.7885 12.8162 99.0211 12.8162C99.7272 12.8162 99.7272 11.7249 99.0211 11.7249C97.7885 11.7249 96.556 11.7249 95.3234 11.7249C94.6173 11.7249 94.6173 12.8162 95.3234 12.8162Z"
      fill="#1F568C"
    />
    <path
      d="M98.9572 16.1664C98.9958 16.1664 99.0343 16.1664 99.0856 16.1664C99.3809 16.1664 99.6377 15.9225 99.6377 15.6143C99.6377 15.319 99.3938 15.0623 99.0856 15.0623C99.0471 15.0623 98.9958 15.0623 98.9572 15.0623C98.6619 15.0623 98.4052 15.319 98.4052 15.6143C98.4052 15.9225 98.6491 16.1664 98.9572 16.1664Z"
      fill="#1F568C"
    />
    <path
      d="M97.2102 16.1664C97.2487 16.1664 97.2872 16.1664 97.3386 16.1664C97.6338 16.1664 97.8906 15.9225 97.8906 15.6143C97.8906 15.319 97.6467 15.0623 97.3386 15.0623C97.3 15.0623 97.2487 15.0623 97.2102 15.0623C96.9149 15.0623 96.6581 15.319 96.6581 15.6143C96.6709 15.9225 96.9149 16.1664 97.2102 16.1664Z"
      fill="#1F568C"
    />
    <path
      d="M95.4871 16.1664C95.5257 16.1664 95.577 16.1664 95.6155 16.1664C95.9108 16.1664 96.1676 15.9225 96.1676 15.6143C96.1676 15.319 95.9237 15.0623 95.6155 15.0623C95.577 15.0623 95.5385 15.0623 95.4871 15.0623C95.1918 15.0623 94.9351 15.319 94.9351 15.6143C94.9479 15.9225 95.1918 16.1664 95.4871 16.1664Z"
      fill="#1F568C"
    />
    <path
      d="M98.9564 18.1562C98.9949 18.1562 99.0334 18.1562 99.0848 18.1562C99.3801 18.1562 99.6368 17.9122 99.6368 17.6041C99.6368 17.3088 99.3929 17.052 99.0848 17.052C99.0463 17.052 98.9949 17.052 98.9564 17.052C98.6611 17.052 98.4043 17.2959 98.4043 17.6041C98.4043 17.8994 98.6482 18.1562 98.9564 18.1562Z"
      fill="#1F568C"
    />
    <path
      d="M97.2089 18.1562C97.2475 18.1562 97.286 18.1562 97.3373 18.1562C97.6326 18.1562 97.8894 17.9122 97.8894 17.6041C97.8894 17.3088 97.6455 17.052 97.3373 17.052C97.2988 17.052 97.2475 17.052 97.2089 17.052C96.9136 17.052 96.6569 17.2959 96.6569 17.6041C96.6697 17.8994 96.9136 18.1562 97.2089 18.1562Z"
      fill="#1F568C"
    />
    <path
      d="M95.4871 18.1555C95.5257 18.1555 95.577 18.1555 95.6155 18.1555C95.9108 18.1555 96.1676 17.9116 96.1676 17.6035C96.1676 17.3082 95.9237 17.0514 95.6155 17.0514C95.577 17.0514 95.5385 17.0514 95.4871 17.0514C95.1918 17.0514 94.9351 17.2953 94.9351 17.6035C94.9479 17.8988 95.1918 18.1555 95.4871 18.1555Z"
      fill="#1F568C"
    />
    <path
      d="M97.4276 6.90903C97.4276 6.27991 97.4276 5.6508 97.4276 5.02168C97.4276 4.21282 96.1694 4.21282 96.1694 5.02168C96.1694 5.6508 96.1694 6.27991 96.1694 6.90903C96.1566 7.71789 97.4276 7.71789 97.4276 6.90903Z"
      fill="#59BA47"
    />
    <path
      d="M90.1615 12.9056C89.5324 12.9056 88.9032 12.9056 88.2741 12.9056C87.4653 12.9056 87.4653 14.1639 88.2741 14.1639C88.9032 14.1639 89.5324 14.1639 90.1615 14.1639C90.9703 14.1639 90.9703 12.9056 90.1615 12.9056Z"
      fill="#59BA47"
    />
    <path
      d="M92.4967 8.34599C92.0474 7.89662 91.6108 7.46009 91.1615 7.01072C90.5837 6.43296 89.6978 7.3317 90.2756 7.89662C90.7249 8.34599 91.1615 8.79536 91.6108 9.23188C92.1758 9.82248 93.0617 8.92375 92.4967 8.34599Z"
      fill="#59BA47"
    />
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.92 8.73242C65.5652 9.96893 64.3858 10.8719 62.9928 10.8618C62.5585 10.8587 62.1471 10.7665 61.7748 10.6033C61.9404 11.7137 62.9235 12.5719 64.122 12.5803C65.4435 12.5899 66.5233 11.564 66.533 10.2889C66.5377 9.68994 66.3041 9.14556 65.92 8.73242Z"
        fill="#4D97FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1576 7.96226C64.8593 7.96739 65.486 8.26487 65.9202 8.73238C65.9895 8.49149 66.0274 8.23813 66.0293 7.9763C66.0417 6.36988 64.7034 5.0585 63.0376 5.04647C61.375 5.03466 60.0154 6.32688 60.0031 7.93329C59.9939 9.12011 60.7224 10.1447 61.775 10.6035C61.7577 10.4892 61.7459 10.3736 61.7468 10.2548C61.7565 8.97861 62.8359 7.9529 64.1576 7.96226Z"
        fill="#02E700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9198 8.73307C65.4856 8.26557 64.8589 7.96786 64.1572 7.96296C62.8355 7.95338 61.7563 8.97931 61.7464 10.2555C61.7455 10.3743 61.7572 10.4899 61.7746 10.604C62.1469 10.7671 62.5583 10.8594 62.9925 10.8625C64.3856 10.8725 65.565 9.96958 65.9198 8.73307Z"
        fill="#58B847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9198 8.73307C65.4856 8.26557 64.8589 7.96786 64.1572 7.96296C62.8355 7.95338 61.7563 8.97931 61.7464 10.2555C61.7455 10.3743 61.7572 10.4899 61.7746 10.604C62.1469 10.7671 62.5583 10.8594 62.9925 10.8625C64.3856 10.8725 65.565 9.96958 65.9198 8.73307Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.044 10.6218C14.6459 10.6117 13.6219 11.0209 12.9717 11.8485C12.3227 12.6776 11.9928 13.7876 11.9822 15.1821C11.9767 15.8914 12.0587 16.5162 12.2296 17.0575C12.3994 17.6016 12.6199 18.0261 12.8907 18.333C12.9341 18.3838 12.9796 18.429 13.0272 18.4736L13.0219 18.4751C13.713 19.214 14.769 19.5875 16.1933 19.5978C16.786 19.602 17.42 19.5057 18.0968 19.3072C18.7715 19.1095 19.111 18.8386 19.1136 18.4956C19.115 18.3178 19.018 18.0756 18.8207 17.7667C18.6232 17.4592 18.4437 17.3037 18.2818 17.3023C18.271 17.3023 18.0434 17.3821 17.6004 17.5394C17.1565 17.6986 16.4894 17.7835 16.0027 17.7801C15.5576 17.7768 15.1377 17.607 14.8325 17.4135C14.4258 17.1553 14.1976 16.6483 14.1442 15.8947C14.1324 15.3091 14.1424 15.312 14.1645 14.4498C14.165 14.4418 14.1657 14.4347 14.1664 14.428C14.1798 14.2381 14.1916 14.1183 14.1916 14.1183C14.2759 13.5315 14.4489 13.1146 14.7087 12.8305C15.0145 12.498 15.4366 12.334 15.978 12.3378C16.5173 12.3418 16.9409 12.5495 17.2463 12.9638C17.5507 13.3756 17.7305 13.9121 17.7845 14.5091C17.4038 14.5303 17.0948 14.5477 16.9825 14.5539C16.54 14.5777 16.0981 14.604 15.6359 14.6277C15.5574 14.6321 15.4795 14.6401 15.4028 14.6473C15.3677 14.6511 15.334 14.6544 15.2993 14.6575C15.1007 14.6747 14.9448 14.8287 14.9321 15.0207C14.9168 15.2541 15.0702 15.4533 15.3049 15.5088C15.3257 15.5143 15.346 15.5166 15.3689 15.5179C15.7569 15.5486 16.1581 15.5789 16.5628 15.6075C17.0611 15.6445 18.6786 15.6904 19.1711 15.7269L20.007 14.3866C20.0091 13.2294 19.6977 12.6625 19.0346 11.858C18.3617 11.0416 17.3657 10.6311 16.044 10.6218Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1593 10.7441C31.7609 10.734 30.7369 11.1432 30.0879 11.9708C29.4388 12.8 29.1085 13.9099 29.0977 15.3044C29.0923 16.0137 29.175 16.6385 29.345 17.1798C29.5162 17.7239 29.7363 18.1484 30.0056 18.4553C30.0502 18.5061 30.0953 18.5513 30.1422 18.5959L30.1375 18.5974C30.8279 19.3364 31.8849 19.7098 33.3096 19.7201C33.9021 19.7243 34.5364 19.6281 35.2136 19.4295C35.8879 19.2319 36.2272 18.9609 36.2297 18.6179C36.2311 18.4401 36.1336 18.1979 35.9357 17.8891C35.7387 17.5815 35.5601 17.426 35.397 17.4247C35.3866 17.4247 35.1584 17.5044 34.7156 17.6618C34.2721 17.8209 33.6051 17.9058 33.1195 17.9024C32.6733 17.8991 32.2529 17.7293 31.9482 17.5359C31.5426 17.2776 31.313 16.7706 31.2599 16.017C31.249 15.4314 31.2587 15.4343 31.2802 14.5722C31.2814 14.5641 31.2814 14.557 31.2821 14.5503C31.2948 14.3605 31.3065 14.2406 31.3065 14.2406C31.3927 13.6539 31.5639 13.2369 31.8244 12.9528C32.1302 12.6203 32.5527 12.4563 33.0925 12.4601C33.6342 12.4641 34.0566 12.6718 34.3622 13.0861C34.6669 13.4979 34.8461 14.0345 34.9002 14.6314C34.5198 14.6526 34.2107 14.67 34.0987 14.6762C33.6563 14.7001 33.2142 14.7264 32.7514 14.75C32.6737 14.7544 32.5959 14.7625 32.5185 14.7696C32.4846 14.7734 32.4492 14.7767 32.415 14.7798C32.2169 14.797 32.0605 14.951 32.0478 15.1431C32.033 15.3764 32.1852 15.5756 32.4199 15.6311C32.4414 15.6366 32.4624 15.6389 32.4846 15.6402C32.8726 15.671 33.2743 15.7013 33.6785 15.7298C34.1772 15.7668 35.7936 15.8127 36.2875 15.8492L37.1236 14.5089C37.1241 13.3517 36.8141 12.7848 36.1507 11.9804C35.4781 11.1639 34.4807 10.7534 33.1593 10.7441Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9188 10.8064C40.477 10.7961 39.0854 11.1039 37.7503 11.7334L37.7138 16.4934L37.6886 19.7695L38.6259 19.7764C38.6259 19.7764 39.8716 19.8158 39.8806 18.6613C39.8836 18.2727 39.8871 17.8025 39.8894 17.497L39.897 16.509L39.9257 12.8012C40.5179 12.7034 41.0804 12.6566 41.6123 12.6604C42.0851 12.6637 42.4725 12.7087 42.7746 12.7952C43.0747 12.8852 43.2906 13.0147 43.4216 13.1856C43.5535 13.3594 43.6406 13.523 43.6852 13.6803C43.7272 13.8378 43.7489 14.0328 43.7473 14.2668L43.7297 16.5364L43.7046 19.8125L44.6396 19.8192C44.6396 19.8192 45.886 19.8588 45.8948 18.7043C45.8964 18.4962 45.898 18.2981 45.8994 18.1123L45.9114 16.552L45.9276 14.462C45.9363 13.3164 45.5859 12.4211 44.8791 11.7795C44.1707 11.1402 43.1849 10.8155 41.9188 10.8064Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.418 17.6019C52.0706 17.9793 51.5407 18.1659 50.8253 18.1607C50.1088 18.1556 49.5801 17.9617 49.2366 17.5791C48.8943 17.1976 48.7268 16.4636 48.7352 15.3766C48.7435 14.2903 48.9222 13.5565 49.2706 13.1768C49.6198 12.7964 50.1513 12.6081 50.8678 12.6133C51.5832 12.6184 52.11 12.8143 52.4517 13.1995C52.7901 13.5844 52.9578 14.3204 52.9494 15.4067C52.9409 16.4937 52.7621 17.2253 52.418 17.6019ZM50.8752 10.8863C49.4958 10.8765 48.4316 11.2555 47.6867 12.0263C46.9409 12.7971 46.5634 13.9073 46.5521 15.3559C46.541 16.8063 46.9016 17.9227 47.6354 18.7027C48.3686 19.4844 49.4267 19.8797 50.8061 19.8897C52.187 19.8995 53.2504 19.5194 53.9972 18.7481C54.7412 17.9789 55.1193 16.8676 55.1304 15.4172C55.1415 13.9685 54.7807 12.853 54.0485 12.0718C53.3137 11.2905 52.256 10.8961 50.8752 10.8863Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.2811 10.9377C59.5061 10.942 58.7485 11.0278 57.9622 11.1973C57.1734 11.3614 56.6491 11.4913 56.3888 11.5831C56.1271 11.6738 55.9213 11.7533 55.7732 11.8202L55.7365 16.6223L55.7113 19.8986L56.6475 19.9053C56.6475 19.9053 57.895 19.9447 57.9038 18.7904C57.9057 18.5779 57.908 18.2661 57.9094 18.0774L57.9204 16.6379L57.9482 13.0166C58.5984 12.8394 59.3793 12.7485 60.2917 12.7432L60.2811 10.9377Z"
        fill="#1D558C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2177 16.9635C26.2129 17.5934 25.6597 17.9025 24.5606 17.8947C23.8688 17.8898 23.3849 17.6589 23.1105 17.1976C22.8363 16.7388 22.7031 15.9939 22.7109 14.9633C22.7185 13.9786 22.9013 13.2925 23.2593 12.9027C23.6159 12.5132 24.1356 12.3216 24.8152 12.3265C25.3478 12.3356 25.8278 12.3869 26.2521 12.4822L26.2177 16.9635ZM27.225 7.47336L26.2907 7.46667L26.2648 10.8295C25.7456 10.7348 25.2427 10.6873 24.7567 10.6837C23.4029 10.6742 22.3628 11.0447 21.6359 11.7955C20.9086 12.5482 20.5392 13.6356 20.5284 15.06C20.5221 15.8433 20.6198 16.5334 20.8224 17.1326C21.0238 17.732 21.273 18.1868 21.5664 18.4979C21.8586 18.8076 22.2041 19.0621 22.6031 19.2593C23.0003 19.4565 23.3489 19.578 23.6476 19.6272C23.9451 19.6749 24.2442 19.6999 24.5468 19.7021C25.8426 19.7114 26.8063 19.428 27.441 18.8526C28.0755 18.2768 28.3954 17.5858 28.4014 16.7807L28.4388 11.9031L28.4629 8.77917C28.4633 8.71767 28.4638 8.66196 28.4643 8.60558C28.473 7.45041 27.225 7.47336 27.225 7.47336Z"
        fill="#1D558C"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="54.7431"
          height="14.9299"
          fill="white"
          transform="translate(11.9834 5.04565)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EdenorMide;
